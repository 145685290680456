<template>
  <div>
    <page-header>
    </page-header>
    <div class="layout-main-body">
      <el-card shadow="never" v-loading="pageLoading">
        <el-row>
          <el-col :span="12">
            <el-form :model="editForm" :rules="rules" ref="editForm" label-width="200px">
              <el-form-item label="选择用户" prop="userId">
                <remote-select v-model="editForm.userId"
                               width="500"
                               width-input="500px"
                               placement="bottom"
                               url="/user/list"
                               :columns="[{field:'nickName',name:'昵称'},{field:'mobile',name:'手机号'}]"
                               label="nickName"
                               value-key="id"
                               :size="5"
                               @change="handleUserChange"
                               clearable></remote-select>
              </el-form-item>
              <el-form-item label="选择套餐" prop="goodsId">
                <div class="combo-list">
                  <div class="combo-box" :class="{'active': currentGoodsVipCard.id === item.id}"
                       v-for="(item,index) in goods" :key="index" @click="handleChangeGoods(item)">
                    <div class="combo-top"><span>{{ item.name }}</span></div>
                    <div class="combo-money">¥{{ item.price }}</div>
                  </div>
                </div>
                <div class="combo-remark" v-if="currentGoodsVipCard.id">
                  需消耗{{ currentGoodsVipCard.consumerCardCount }}张VIP年卡
                </div>
              </el-form-item>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="editForm.orderRemark" :rows="5" style="width: 500px"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleSubmit">立即开通</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
        <el-dialog
            title="确认订单"
            :visible.sync="dialogVisible"
            width="40%">
          <el-form label-width="200px" class="order-confirm">
            <el-form-item label="当前用户">
              {{ currentUser.nickName }}({{ currentUser.mobile }})
            </el-form-item>
            <el-form-item label="开通VIP时长">
              {{ currentGoodsVipCard.month }} 月
            </el-form-item>
            <el-form-item label="备注">
              {{ this.editForm.orderRemark }}
            </el-form-item>
            <el-form-item label="当前操作人">
              {{ this.$store.getters.realName }}
            </el-form-item>
            <el-form-item label="订单总金额">
              <span class="amount-total">¥ {{ currentGoodsVipCard.price }}</span>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button plain @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleSubmitFinal" :loading="loadingSubmit">提交</el-button>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
import { OmsGoodsVipCardApi, OmsOrderApi, AccountApi } from '@/api'
import Dictionary from '@/utils/dictionary'
import { VIP_OPEN_TYPE } from '@/utils/contants'

const defaultForm = () => {
  return {
    goodsId: '',
    vipOpenType: '',
    orderDesc: '',
    orderRemark: '',
    userId: '',
    payWay: Dictionary.userOrderPayWay.offline.code
  }
}
export default {
  name: 'OrderVipCreate',
  data () {
    return {
      currentUser: {},
      currentGoodsVipCard: {},
      currentAccount: {},
      goods: [],
      pageLoading: false,
      editForm: defaultForm(),
      rules: {
        userId: [
          {
            required: true,
            message: '请选择用户',
            trigger: 'change'
          }
        ],
        goodsId: [
          {
            required: true,
            message: '请选择套餐',
            trigger: 'change'
          }
        ]
      },
      loadingSubmit: false,
      dialogVisible: false
    }
  },
  methods: {
    async initData () {
      this.pageLoading = true
      this.currentAccount = await AccountApi.getAccount()
      const goodsResult = await OmsGoodsVipCardApi.list({
        page: 0,
        size: 10,
        status: Dictionary.userVipGoodsCard.publish.code
      })
      this.goods = goodsResult.rows
      this.handleChangeGoods(this.goods[0])
      this.pageLoading = false
    },
    handleChangeGoods (item) {
      this.currentGoodsVipCard = item
      this.editForm.goodsId = item.id
    },
    handleUserChange (val) {
      this.currentUser = val
      if (this.currentUser.vipExpirationTime) {
        this.editForm.vipOpenType = Dictionary.userVipType.reOpen.code
      } else {
        this.editForm.vipOpenType = Dictionary.userVipType.firstOpen.code
      }
    },
    handleSubmit () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          if (this.currentAccount.totalVipCardCount >= this.currentGoodsVipCard.consumerCardCount) {
            this.dialogVisible = true
          } else {
            this.$confirm('帐户VIP年卡数不足，无法开通VIP，请联系管理员充值')
          }
        } else {
          return false
        }
      })
    },
    handleSubmitFinal () {
      this.loadingSubmit = true
      this.editForm.orderDesc = this.editForm.vipOpenType === VIP_OPEN_TYPE.FIRST_OPEN ? '素客通-开通VIP' : '素客通-续费VIP'
      OmsOrderApi.createOrder(this.editForm).then(() => {
        this.dialogVisible = false
        this.loadingSubmit = false
        this.$router.push('/oms/order/vip/create/result?source=2')
      }).catch(() => {
        this.loadingSubmit = false
        this.dialogVisible = false
      })
    }
  },
  created: function () {
    this.initData()
  }
}
</script>

<style lang="less">
.order-confirm {
  .el-form-item__content {
    margin-left: 200px !important;
  }

  .el-form-item__label {
    color: #999999;
  }

  .amount-total {
    font-size: 20px;
    font-weight: 600;
    color: #f14343;
  }
}

.combo-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .combo-box {
    cursor: pointer;
    width: 211px;
    height: 80px;
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: #f8f8f8;

    .combo-top {
      color: #222;
      font-size: 18px;
      line-height: 18px;
    }

    .combo-money {
      margin-top: 10px;
      font-size: 16px;
      line-height: 16px;
      color: #505050;
    }
  }

  .active {
    background-color: #fdf6ec;
    border: 1px solid #fdac1e;
  }
}

.combo-remark {
  color: #f14343;
  font-weight: 400;
}
</style>
